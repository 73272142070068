<script>
import {required} from "vuelidate/lib/validators";
import HttpRequest from "@/http/HttpRequest"
import Preference from "@/data/Preference"

import Vue from 'vue';
// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import Swal from "sweetalert2";
// Init plugin
Vue.use(Loading);

//Common HTTP object for managing request response
let httpRequest = new HttpRequest()
let preference = new Preference()

export default {
  data() {
    return {
      email: "",
      orgOwnerEmail: "",
      submitted: false,
      error: null,
      tryingToReset: false,
      isResetError: false,
      org_name: "",
      user_id: "",
      user_email: "",
      org_address: "",
      org_domain: "",
      employeeSlot: [
        'Select team size',
        '1-5',
        '5-20',
        '20-50',
        '50-100',
        '100+'
      ],
      number_of_employee: "",
    };
  },
  validations: {
    org_name: {required},
    org_address: {required},
    number_of_employee: {required},
  },
  created() {
    document.body.classList.add("auth-body-bg");
    this.user_id = this.$route.query.user_id
    if (typeof this.user_id === 'undefined') {
      this.user_id = preference.getProfileData().userId
    }
  },
  mounted() {

  },
  methods: {
    sendProjectAssignRequest() {
      let _this = this
      // Init loader
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        onCancel: this.onCancel,
        backgroundColor: "#ffffff",
        loader: "dots"

      });

      // Init sweet alert2
      let swal = this.$swal

      httpRequest.orgMemberRequest(_this.orgOwnerEmail, _this.user_id).then(function (response) {
        loader.hide()
        if (response.success) {
          _this.$bvModal.hide('modal-member-request')
          Swal.fire("Your mail has been sent!", "You will be notified after organization owner's approval.", "success").then(result => {
            if (result) {
              _this.$router.push({path: "/"});
            }
          })
        } else {
          swal(response.message);
        }
      })
    },
    goToDashboard() {
      this.$router.push({path: "/"});
    },
    createOrganization() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        let _this = this
        // Init loader
        let loader = this.$loading.show({
          // Optional parameters
          container: this.fullPage ? null : this.$refs.formContainer,
          canCancel: false,
          onCancel: this.onCancel,
          backgroundColor: "#ffffff",
          loader: "dots"

        });

        // Init sweet alert
        let swal = this.$swal

        if (_this.number_of_employee === 'Select team size') {
          _this.number_of_employee = ''
        }


        httpRequest.createOrganization(_this.org_name, _this.number_of_employee, _this.user_id, _this.org_address).then(function (response) {
          loader.hide()
          if (response.success) {
            _this.$router.push({path: "/"});
          } else {
            swal(response.message);
          }
        })
      }
    }
  }
};
</script>

<template>
  <div class="container-fluid p-0">
    <div class="row no-gutters">
      <div class="col-lg-5 col-xl-4" style="background-color: #f1f5f7">
        <div class="form-page-content p-4 d-flex align-items-center flex-column justify-content-center min-vh-100">

          <div class="text-center">
            <a href="/">
              <img src="@/assets/images/workany-v-logo.png" height="130" width="180" alt="WorkAny"/>
            </a>
            <h4 class="font-size-18 mt-3 mt-md-4">About to complete your registration!</h4>
            <!--       <p class="text-muted">Continue as an employee or create your own organization?</p>
                        <div class="mt-3 mt-md-4 text-center">
                          <b-button variant="primary" v-b-modal.modal-member-request type="submit">
                            Continue as an employee
                          </b-button>
                        </div>
                        <p class="font-size-16 mt-3 mt-md-4">OR</p>
                        <hr/>
                        <h4 class="font-size-18 mt-2">Create your own organization</h4>-->
          </div>

          <div class="p-2 mt-3 mt-md-5 w-100" style="max-width: 440px">
            <b-alert
                v-model="isResetError"
                class="mb-4"
                variant="danger"
                dismissible>{{ error }}
            </b-alert>

            <form class="form-horizontal" @submit.prevent="">

              <div class="form-group auth-form-group-custom mb-3 mb-md-4">
                <i class=" ri-building-2-line auti-custom-input-icon"></i>
                <label for="orgname">Organization Name</label>
                <input
                    type="text"
                    class="form-control"
                    id="orgname"
                    :class="{ 'is-invalid': submitted && $v.org_name.$error }"
                    placeholder="Enter name"
                    v-model="org_name"/>
                <div
                    v-if="submitted && !$v.org_name.required"
                    class="invalid-feedback">Organization name is required.
                </div>
              </div>

              <div class="form-group auth-form-group-custom mb-3 mb-md-4">
                <i class="mdi mdi-human auti-custom-input-icon"></i>
                <label for="team-size">Team Size</label>


                <select
                    class="form-control appearance-none"
                    :class="{ 'is-invalid': submitted && $v.number_of_employee.$error }"
                    id="team-size" v-model="number_of_employee">
                  <option v-for="(slot , index) in employeeSlot" v-bind:key="index">
                    {{ slot }}
                  </option>
                </select>

                <div
                    v-if="submitted && !$v.number_of_employee.required"
                    class="invalid-feedback">Number of employee is required.
                </div>
              </div>

              <div class="form-group auth-form-group-custom mb-3 mb-md-4">
                <i class="mdi mdi-home-search-outline auti-custom-input-icon"></i>
                <label for="orgadress">Address</label>
                <input
                    type="text"
                    class="form-control"
                    id="orgadress"
                    :class="{ 'is-invalid': submitted && $v.org_address.$error }"
                    placeholder="Enter address"
                    v-model="org_address"
                />
                <div
                    v-if="submitted && !$v.org_address.required"
                    class="invalid-feedback">Organization address is required.
                </div>

              </div>
              <div class="mt-3 mt-md-4 text-center">
                <b-button
                    variant="primary"
                    size="lg"
                    class="customs-bg-color"
                    type="submit"
                    v-on:click="createOrganization"
                >CREATE ORGANIZATION
                </b-button>
              </div>

            </form>
          </div>

          <div class="mt-3 mt-md-4 text-center position-absolute fixed-bottom">
            <div style="border-bottom: 1px solid #ddd "></div>
            <p class="mt-3 mt-md-4 text-center">© 2023 WORKANY. Crafted by <a href="https://w3engineers.com/"
                                                                              class="text-primary" target="_blank">W3
              Engineers Ltd</a>.</p>
          </div>
        </div>
      </div>

      <!--member request modal-->
      <div class="align-items-center text-center text-md-left">
        <b-modal id="modal-member-request" centered title="Join an existing organization" hide-footer>
          <br class="form-horizontal" @submit.prevent="">
          <p>Send a request to the organization's owner so they can add you as a team member</p>
          <div class="form-group auth-form-group-custom mb-2 my-2">
            <i class="ri-mail-line auti-custom-input-icon"></i>
            <label for="useremail">Email</label>
            <input
                v-model="orgOwnerEmail"
                type="email"
                class="form-control"
                id="useremail"
                placeholder="Enter organization owner email"
            />
          </div>

          <div class="mt-4 text-center">
            <b-button
                variant="primary"
                size="lg"
                type="submit"
                v-on:click="sendProjectAssignRequest"
            >Send request to join
            </b-button>
          </div>
        </b-modal>
      </div>

      <div class="col-lg-7 col-xl-8 d-none d-lg-block">
        <div class="min-vh-100 py-5 d-flex align-items-center">
          <div class="w-100">
            <h4 class="font-size-28 text-center px-3">Do you need to create a new organization in WorkAny?</h4>
            <p class="text-center px-3">Let's get started</p>
            <div class="d-flex justify-content-center">
              <img src="@/assets/images/bg/create_org.jpg" height="100%" width="50%" alt="create organization"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.customs-bg-color {
  background-color: #ff7c47 !important;
}
</style>
